/* eslint-disable no-restricted-globals, no-underscore-dangle, filenames/match-regex */

import { clientsClaim } from "workbox-core";
import { createHandlerBoundToURL as createHandlerBoundToUrl, precacheAndRoute } from "workbox-precaching";
import { registerRoute } from "workbox-routing";

clientsClaim();
precacheAndRoute(self.__WB_MANIFEST);

const fileExtensionRegexp = new RegExp("/[^/?]+\\.[^/]+$");

registerRoute(
  ({ request, url }) => {
    if (request.mode !== "navigate") return false;
    if (url.pathname.startsWith("/_")) return false;
    if (url.pathname.match(fileExtensionRegexp)) return false;

    return true;
  },
  createHandlerBoundToUrl(`${process.env.PUBLIC_URL}/index.html`)
);

self.addEventListener("message", (event) => {
  if (event.data && event.data.type === "SKIP_WAITING") {
    self.skipWaiting();
  }
});
